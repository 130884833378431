import React from 'react';

const UbicacionGoogleMap=()=>{
    return(
        <iframe
            title="mapa_ubicacion_duana"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1991.3425006150972!2d-76.5393271!3d3.4266648!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e30a699b5a32bcb%3A0x37f5ac397e9ce249!2sDuana%20Y%20Cia%20Ltda!5e0!3m2!1ses-419!2sco!4v1581521836116!5m2!1ses-419!2sco"
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen=""
            style={{
                border:"0px",
            }}
            >    
        </iframe>
    )
}

export default UbicacionGoogleMap;