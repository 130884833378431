import React from 'react';
import Slider from "react-slick";

class SlideClientes extends React.Component {

    render() {
 
      /*const settings = {
        className: "center clientes_slick_slider",
        dots: false,
        arrows:false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 100,
        cssEase: "linear",
        variableWidth: true
      };   */     
// Así va para celulares
      const settings = {
        className: "center clientes_slick_slider",
        dots: false,
        arrows:false,
        swipeToSlide: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        speed: 4000,//estaba en 3000
        //rtl:true,
        autoplaySpeed: 100, //estaba en 0
        cssEase: "linear",
        variableWidth: true,
        centerMode: true
      };
 
      const insertarCategorias=()=>{
          return this.props.imagenesClientes.map((_img, index)=>{
              return(
                  <div className="img_slide_clientes" key={`img_${index}`}>
                    <img src={_img} alt={_img} />
                  </div>
              )
          })
      }
  
      return (
        <div id="completo" className="seccion_clientes">
          <Slider {...settings}>
              {insertarCategorias()}
          </Slider>
        </div>
      );
    }
  }

export default SlideClientes;