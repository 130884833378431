import React from 'react';

const InformacionEmpresa=()=>{
    return(
        <section className="informacion_empresa text_gray_light">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
                <a className="nav-link text_gray_light active" id="sobre-nosotros-tab" data-toggle="tab" href="#sobre-nosotros" role="tab"
                    aria-controls="sobre-nosotros" aria-selected="true">Sobre Nosotros</a>
            </li>
            <li className="nav-item">
                <a className="nav-link text_gray_light" id="politica-calidad-tab" data-toggle="tab" href="#politica-calidad" role="tab"
                    aria-controls="politica-calidad" aria-selected="false">Política de Calidad</a>
            </li>
            <li className="nav-item">
                <a className="nav-link text_gray_light" id="mision-tab" data-toggle="tab" href="#mision" role="tab" aria-controls="mision"
                    aria-selected="false">Misión</a>
            </li>
            <li className="nav-item">
                <a className="nav-link text_gray_light" id="vision-tab" data-toggle="tab" href="#vision" role="tab" aria-controls="vision"
                    aria-selected="false">Visión</a>
            </li>
        </ul>
        <div className="tab-content" id="myTabContent">
            <div className="tab-pane contendor_texto_info_empresa fade show active" id="sobre-nosotros" role="tabpanel"
                aria-labelledby="sobre-nosotros-tab">
                Somos una empresa dedicada a la importación, comercialización y distribución de dispositivos médicos
                para el sector salud, que busca ser parte fundamental del mejoramiento de calidad de vida de la
                población, con altos índices de efectividad, con énfasis en la seguridad del paciente a través de un
                talento humano comprometido, calificado y con vocación de servicio.
            </div>
            <div className="tab-pane contendor_texto_info_empresa fade" id="politica-calidad" role="tabpanel"
                aria-labelledby="politica-calidad-tab">
                Es política de Madhos S.A.S. fidelizar a nuestros clientes y proveedores, creando una cultura
                organizacional, orientada a la excelencia en el servicio, cumpliendo los requisitos aplicables y
                mejorando continuamente cada uno de nuestros procesos.
            </div>
            <div className="tab-pane contendor_texto_info_empresa fade" id="mision" role="tabpanel"
                aria-labelledby="mision-tab">
                Somos una empresa que importa, comercializa y distribuye para el sector salud dispositivos médicos de
                alta tecnología y con altos estándares de calidad en cada uno de nuestros procesos, con un equipo humano
                comprometido, integro y competente que busca siempre mejorar la calidad de vida de la población.
            </div>
            <div className="tab-pane contendor_texto_info_empresa fade" id="vision" role="tabpanel"
                aria-labelledby="vision-tab">
                Ser reconocidos en el mercado nacional e internacional por ser una compañía líder en la importación,
                comercialización y distribución de dispositivos médicos para el sector salud. Fortaleciendo la eficacia
                de los procesos, el desarrollo del talento humano y la rentabilidad de la empresa; contribuyendo así al
                mejoramiento de la calidad de vida de los usuarios.
            </div>
        </div>
        </section>
    )
}

export default InformacionEmpresa;