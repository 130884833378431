import React from 'react';

class FormularioContacto extends React.Component {
    render() {
        return (
            <div className="form_container">
                <form action="">
                    <div className="form-group">
                        <input type="text" className="form-control form-control-md" placeholder="Nombre" />
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control form-control-md" placeholder="Email" />
                    </div>
                    <div className="form-group">
                        <textarea rows="4" cols="50" className="form-control form-control-md" placeholder="Mensaje"></textarea>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary custom_btn_blue_white form-control-lg btn-block">Enviar</button>
                    </div>
                </form>
            </div>
                    )
                }
            }
            
export default FormularioContacto;