import React from "react";
import { Link } from 'react-router-dom';

class Header extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            menuElements:[
                {
                  id:"menuInicio",
                  name:"Inicio",
                  route:"/"
                },
                {
                  id:"menuProductos",
                  name:"Productos",
                  route:"/productos"
                },
                {
                  id:"menuServicios",
                  name:"Servicios",
                  route:"/servicios"
                },
                {
                  id:"menuClinicas",
                  name:"Clientes",
                  route:"/clientes"
                },
                {
                  id:"menuProveedores",
                  name:"Proveedores",
                  route:"/proveedores"
                },
                {
                  id:"menuIntranet",
                  name:"Intranet",
                  route:"/intranet"
                },
                {
                  id:"menuContacto",
                  name:"Contacto",
                  route:"/contacto"
                }
              ],
            responsiveClass : "hide-menu"
        }
    }

    //responsiveClass ="hide-menu";

    goToIntranet(){
      window.location="http://madhos.net/#/login";
    }

    setMenuState(e){
      this.toggleMenu();
      this.props.cambiarActiveMenuElement(e.target.id);
    }

    toggleMenu(){
      this.setState({
        responsiveClass: (this.state.responsiveClass === "hide-menu") ? "" : "hide-menu"
      });
    }

    generarMenu(){
      return(
          this.state.menuElements.map(menu_item => {
            let _className = (menu_item.id === this.props.activeMenuElement) ? "active_menu_element" : "";
            if(menu_item.route == "/intranet"){
              return <li key={menu_item.id}><a id={menu_item.id} className={_className} onClick={()=>this.goToIntranet()} >{menu_item.name}</a></li>
            }
            return <li key={menu_item.id}><Link to={menu_item.route} id={menu_item.id} className={_className} onClick={(e)=>this.setMenuState(e)} >{menu_item.name}</Link></li>
          })
      )
    }

    render(){
        return(
            <header className="respHeader">
              <div className="img_header_container">
                <img src="./img/logo_madhos.png" className="main_logo_madhos" alt="logo_madhos" />

                  <div className="btn-resp-menu" onClick={()=>{this.toggleMenu()}}>
                    <div></div> <div></div> <div></div>
                  </div>

              </div>
      
              <nav id="responsiveNav" className={"responsiveNav " + this.state.responsiveClass}>
                  <ul>
                      {this.generarMenu()}
                  </ul>
              </nav>
      
            </header>
        );
    }
}

export default Header;
