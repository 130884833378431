import React from 'react';
import Footer from './Parts/GeneralParts/Footer';
import GaleriaProductos from './Parts/ListadoProducto/GaleriaProductos';
import MenuListadoProducto from './Parts/ListadoProducto/MenuListadoProducto';
//import menuProductos from './Parts/infoDemoMenu.js';
import API from '../Requests/API';
import axios from 'axios';

var CancelToken = axios.CancelToken;
var source = CancelToken.source();

var productosVisiblesDemo = [
    {
        img_miniatura: "./img/miniatura_demo_producto_1.jpg",
        img_banner: [{ url: "./img/miniatura_demo_producto_1_banner.jpg", alt: "texto_alternativo" }],
        nombre: "Titulo del Producto",
        descripcion: "Breve Fragmento de la descripción del producto..."
    },
    {
        img_miniatura: "./img/miniatura_demo_producto_2.jpg",
        img_banner: [{ url: "./img/miniatura_demo_producto_2_banner.jpg", alt: "texto_alternativo" }],
        nombre: "Titulo del Producto",
        descripcion: "Breve Fragmento de la descripción del producto..."
    },
    {
        img_miniatura: "./img/miniatura_demo_producto_3.jpg",
        img_banner: [{ url: "./img/miniatura_demo_producto_3_banner.jpg", alt: "texto_alternativo" }],
        nombre: "Titulo del Producto",
        descripcion: "Breve Fragmento de la descripción del producto..."
    },
    {
        img_miniatura: "./img/miniatura_demo_producto_4.jpg",
        img_banner: [{ url: "./img/miniatura_demo_producto_1_banner.jpg", alt: "texto_alternativo" }],
        nombre: "Titulo del Producto",
        descripcion: "Breve Fragmento de la descripción del producto..."
    },
    {
        img_miniatura: "./img/miniatura_demo_producto_5.jpg",
        img_banner: [{ url: "./img/miniatura_demo_producto_5_banner.jpg", alt: "texto_alternativo" }],
        nombre: "Titulo del Producto",
        descripcion: "Breve Fragmento de la descripción del producto..."
    },
    {
        img_miniatura: "./img/miniatura_demo_producto_6.jpg",
        img_banner: [{ url: "./img/miniatura_demo_producto_6_banner.jpg", alt: "texto_alternativo" }],
        nombre: "Titulo del Producto",
        descripcion: "Breve Fragmento de la descripción del producto..."
    },
    {
        img_miniatura: "./img/miniatura_demo_producto_7.jpg",
        img_banner: [{ url: "./img/miniatura_demo_producto_7_banner.jpg", alt: "texto_alternativo" }],
        nombre: "Titulo del Producto",
        descripcion: "Breve Fragmento de la descripción del producto..."
    },
    {
        img_miniatura: "./img/miniatura_demo_producto_8.jpg",
        img_banner: [{ url: "./img/miniatura_demo_producto_8_banner.jpg", alt: "texto_alternativo" }],
        nombre: "Titulo del Producto",
        descripcion: "Breve Fragmento de la descripción del producto..."
    },
    {
        img_miniatura: "./img/miniatura_demo_producto_9.jpg",
        img_banner: [{ url: "./img/miniatura_demo_producto_9_banner.jpg", alt: "texto_alternativo" }],
        nombre: "Titulo del Producto",
        descripcion: "Breve Fragmento de la descripción del producto..."
    },
    {
        img_miniatura: "./img/miniatura_demo_producto_10.jpg",
        img_banner: [{ url: "./img/miniatura_demo_producto_10_banner.jpg", alt: "texto_alternativo" }],
        nombre: "Titulo del Producto",
        descripcion: "Breve Fragmento de la descripción del producto..."
    },
    {
        img_miniatura: "./img/miniatura_demo_producto_11.jpg",
        img_banner: [{ url: "./img/miniatura_demo_producto_11_banner.jpg", alt: "texto_alternativo" }],
        nombre: "Titulo del Producto",
        descripcion: "Breve Fragmento de la descripción del producto..."
    },
    {
        img_miniatura: "./img/miniatura_demo_producto_12.jpg",
        img_banner: [{ url: "./img/miniatura_demo_producto_12_banner.jpg", alt: "texto_alternativo" }],
        nombre: "Titulo del Producto",
        descripcion: "Breve Fragmento de la descripción del producto..."
    }
];


class ListadoProducto extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            //información de los productos de la galería (carga una por defecto, los demás los consulta al hacer click en cada categoría)
            productosVisibles: productosVisiblesDemo,
            //menú
            menuProductos__: [
                {
                    title: "Insumos Hospitalarios",
                    submenuTitle: [
                        {
                            title: "Guantes Examinación",
                            submenuTitle: null,
                            searchBy: "P_1_1_a"
                        },
                        {
                            title: "Jeringas",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Guantes Esterilización",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        }
                    ],
                    searchBy: "P_1"
                },
                {
                    title: "Terapia Intravenosa",
                    submenuTitle: [
                        {
                            title: "Buretrol",
                            submenuTitle: null,
                            searchBy: "P_1_1_a"
                        },
                        {
                            title: "Macrogoteo",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Microgoteo",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Equipo Pericraneal",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Equipo Adm. Sangre",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Tapón Heparimizado",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Cateter Intravenosos",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        }
                    ],
                    searchBy: "P_1"
                },
                {
                    title: "Terapia Respiratoria",
                    submenuTitle: [
                        {
                            title: "Canulas Nasales",
                            submenuTitle: null,
                            searchBy: "P_1_1_a"
                        },
                        {
                            title: "Máscaras Oxigeno",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Kit Ventura",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Humidificadores",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Filtros",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Circuitos Ventilación",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Máscaras Anestesia",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Balones Anestesia",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Cateter Mount",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Circuito Anestesia",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Trampas Luke",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        }
                    ],
                    searchBy: "P_1"
                },
                {
                    title: "Insumos para Esterilización",
                    submenuTitle: [
                        {
                            title: "Papel Crepado",
                            submenuTitle: null,
                            searchBy: "P_1_1_a"
                        },
                        {
                            title: "Papel Grado Médico",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Indicadores Biológicos",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Cintas Indicadoras",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Indicadores Químicos",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "test Bowie Dick",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        }
                    ],
                    searchBy: "P_1"
                },
                {
                    title: "Instrumental Quirúrgico",
                    submenuTitle: [
                        {
                            title: "Básico",
                            submenuTitle: null,
                            searchBy: "P_1_1_a"
                        },
                        {
                            title: "Especializado",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        }
                    ],
                    searchBy: "P_1"
                },
                {
                    title: "Insumos Alto Costo",
                    submenuTitle: [
                        {
                            title: "Terapia Vac.",
                            submenuTitle: null,
                            searchBy: "P_1_1_a"
                        },
                        {
                            title: "Kit de Traqueostomía",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Cateter Doble",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Bombas Irrigación Artus",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        }
                    ],
                    searchBy: "P_1"
                },
                {
                    title: "Terapia Cuidado Crítico",
                    submenuTitle: [
                        {
                            title: "Transductores de Presión",
                            submenuTitle: null,
                            searchBy: "P_1_1_a"
                        },
                        {
                            title: "Infusores de Presión",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        }
                    ],
                    searchBy: "P_1"
                },
                {
                    title: "Material Osteosíntesis",
                    submenuTitle: [
                        {
                            title: "Trauma",
                            submenuTitle: null,
                            searchBy: "P_1_1_a"
                        },
                        {
                            title: "Reemplazos Articulares",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Columna",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Fijación Extrema",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Osteobiológicos",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Maxilofacial",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Minifragmentos",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Motores",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        }

                    ],
                    searchBy: "P_1"
                },
                {
                    title: "Cuidado de Heridas",
                    submenuTitle: [
                        {
                            title: "Apósitos Especiales",
                            submenuTitle: null,
                            searchBy: "P_1_1_a"
                        },
                        {
                            title: "Compresas",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Apósitos Transparentes",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        }
                    ],
                    searchBy: "P_1"
                },
                {
                    title: "Cierre de Heridas y Cicatrización",
                    submenuTitle: [
                        {
                            title: "Cintas Adhesivas",
                            submenuTitle: null,
                            searchBy: "P_1_1_a"
                        },
                        {
                            title: "Vendajes",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Yesos",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        }
                    ],
                    searchBy: "P_1"
                },
                {
                    title: "Ropa Desechable",
                    submenuTitle: [
                        {
                            title: "Bata Cirujano",
                            submenuTitle: null,
                            searchBy: "P_1_1_a"
                        },
                        {
                            title: "Paquete Laparotomía",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Paquete Angiogratía",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Bata Paciente",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        },
                        {
                            title: "Campos Envolvedero",
                            submenuTitle: null,
                            searchBy: "P_1_2_b"
                        }
                    ],
                    searchBy: "P_1"
                }
            ],
            menuProductos: [],
            responsiveClass: "hide_btn_menu_categorias_productos"
        }
    }

    componentDidMount(){
        this._isMounted = true;
        this.obtenerMenu();
        this.obtenerProductosVisibles(this.props.categoriasSeleccionadas);
        // this.setState({
        //     this.props.categoriasSeleccionadas
        // });
    }

    componentWillUnmount(){
        this._isMounted = false;
       // source.cancel('Operation canceled by the user.');
    }

    //1 formatea los objetos acorde a la estructura de objeto del menú
    formatearObjetos(response){
        var objetosFormateados = [];
        response.map( item => {
            var obj = {
                es_padre: item.es_padre,
                categoria_padre_id: item.categoria_padre_id,
                name: item.nombre,
                img: item.img,
                subOptions: [],
                id: item.id_categorias_productos
            }
            objetosFormateados.push(obj);
        });  
        return objetosFormateados;  
    }
    
    //2. Incluye todos los demás niveles
    adicionarHijos(primerNivelItem, response){
        response.map( item => {
        if(item.categoria_padre_id === primerNivelItem.id){
            primerNivelItem.subOptions.push(item);
            if(primerNivelItem.es_padre === "1"){
            this.adicionarHijos(item, response); 
            }
        }
        })
    }
    
    generarformatoArbolMenu(response){
        //formatea los objetos recibidos al modelo de elementos del menu
        var objetosFormateados = this.formatearObjetos(response);
        //Obtiene todas las ramas padre
        var primerNivel = objetosFormateados.filter(item => item.categoria_padre_id === "0");
        //Incluye todos los demás niveles
        primerNivel.map(item => {
        this.adicionarHijos(item, objetosFormateados);
        });
        console.log("acá el primer nivel: ");
        console.log(primerNivel);
        return primerNivel;
    }

    obtenerMenu(){
        fetch(API.getMenu)
        .then(data=>data.json())
        .then(data2=>{
            console.log(data2);
            if(this._isMounted){
                var menuFormateado = this.generarformatoArbolMenu(data2);
                //Poner de primero Ropa Desechable y Material Osteosintesis
                var menuFormateado = this.definirOrden(menuFormateado);

                console.log("abajo el menu formateado: ------------------");
                console.log(menuFormateado);
                this.setState({
                    menuProductos: menuFormateado
                });
                console.log("en el state es este: ", this.state.menuProductos);
            }  
        })
        .catch(err=>console.log("el error: ",err))
    }

    definirOrden(menuFormateado){
        var menuFormateadoElementosEliminados = menuFormateado.filter( item => item.id !== "65" && item.id !== "48");
        var ropaDesechable = menuFormateado.filter( item => item.id === "65")[0];
        var materialOsteosintesis = menuFormateado.filter( item => item.id === "48")[0];
        menuFormateadoElementosEliminados.unshift(ropaDesechable, materialOsteosintesis);
        console.log("menu formateado elementos eliminadossss");
        console.log(menuFormateadoElementosEliminados);
        return menuFormateadoElementosEliminados;
    }

    toggleMenu() {
        this.setState({
            responsiveClass: (this.state.responsiveClass === "hide_btn_menu_categorias_productos") ? "" : "hide_btn_menu_categorias_productos"
        });
    }

    obtenerProductosVisibles(categoriaSeleccionada) {
        console.log("ENTRA EN OBTENER PRODUCTOS VISIBLES de LISTADO **************************", categoriaSeleccionada);
        console.log("falta añadir parametros");

        //si no ha seleccionado osteosintesis, no haga la petición y muestre los productos demo
        if(!(!!(categoriaSeleccionada["48"]))){
            this.setState({
                productosVisibles: productosVisiblesDemo
            });


            //muestra el estado selección desde app y desde componbente menu
            //alert("categoria seleccionada desde listado producto: ",JSON.stringify(categoriaSeleccionada));

            //si comento la linea de abajo ya categoria seleccionada no se me actualiza al pasar de pagina
            //pero da error si en el componentDidMount le digo que cargue esa propiedad
            //this.props.definirCategoriasSeleccionadas(categoriaSeleccionada);
            //alert("la categoria seleccionada de APP: ", JSON.stringify(this.props.categoriaSeleccionada));


            //la función retorna y no sigue a hacer la petición
            return false;
        }



        console.log("NO PASA YA POR ACA");
        //productosVisiblesDemo

        fetch(API.getProductos + '?categorias=49,48')
        .then(data=>data.json())
        .then(jsonData=>{
            console.log(jsonData);
            const productosVisiblesFormateados = this.formatearProductosVisibles(jsonData);
            if(this._isMounted){
                this.setState({
                    productosVisibles: productosVisiblesFormateados
                });
                console.log("este es el listado de los productos: ", this.state.productosVisibles);
            }  
        })
        .catch(err=>console.log("el error: ",err))

        
    }

    formatearProductosVisibles(respuesta){
        var arrayProductos = [];
        respuesta.forEach(item => {
            var arrayBanner = [];
            var obj = { url: item.img_banner, alt: "texto_alternativo" };
            arrayBanner.push(obj);
            item.img_banner = arrayBanner;
            arrayProductos.push(item);
        })
        console.log("FORMATEO PRODUCTO VISIBLE???");
        console.log(arrayProductos);
        console.log("debe estar arriba de esto");
        return arrayProductos;
    }

    async fetchProductos(){

    }

    render() {
        return (
            <React.Fragment>
                <section className="titulo_pagina_productos">
                    <span className="text_regular_23 text_blue_dark">Productos</span>
                    <hr className="hr_titulo_pagina_productos" />

                    <div className="btn-resp-menu btn_menu_categorias_productos" onClick={() => { this.toggleMenu() }}>
                        <i className="fa fa-ellipsis-v"></i>
                        VER CATEGORÍAS
                    </div>

                </section>

                <div className="contenedor_galeria_y_menu_producto">
                    <MenuListadoProducto
                        menuProductos={this.state.menuProductos}
                        responsiveClass={this.state.responsiveClass}
                        categoriasSeleccionadas={this.props.categoriasSeleccionadas}
                        obtenerProductosVisibles={(categoriaSeleccionada)=>this.obtenerProductosVisibles(categoriaSeleccionada)}
                    />
                    <GaleriaProductos productosVisibles={this.state.productosVisibles} obtenerProductoSeleccionado={this.props.obtenerProductoSeleccionado} routerProps={this.props.routerProps} />
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}

export default ListadoProducto;