import React from 'react';
import Footer from './Parts/GeneralParts/Footer';
import BannerPrincipal from './Parts/GeneralParts/BannerPrincipal';
import DescripcionStandard from './Parts/DescripcionStandard';
import FichaTecnicaContainer from './Parts/Producto/FichaTecnicaContainer';
import VolverListadoProductos from './Parts/Producto/VolverListadoProductos';
import API from '../Requests/API';

class Producto extends React.Component {

    _isMounted = false;

    constructor(props){
        super(props);
        this.state = {
            title:"Producto de Ejemplo",
            content:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            fichaTecnica:[
                {
                    titulo_descriptor_producto:"Interfaces",
                    valor:"Inalámbrica, Tecnología seca 360° wireless, USB 2.0, Ethernet"
                },
                {
                    titulo_descriptor_producto:"Clase de productos médicos",
                    valor:"lla"
                },
                {
                    titulo_descriptor_producto:"Tipo de pantalla",
                    valor:"Pantalla táctil de 7'"
                },
                {
                    titulo_descriptor_producto:"Medición de la temperatura / Métodos de medición",
                    valor:"Directa, predictiva Genius 2 operating manual ear"
                },
                {
                    titulo_descriptor_producto:"Saturación de oxígeno (SpO2) / Métodos de medición",
                    valor:"Pulsioximetría"
                },
                {
                    titulo_descriptor_producto:"Medición de la presión arterial / Método de medición:",
                    valor:"Oscilométrico"
                },
                {
                    titulo_descriptor_producto:"Alimentación",
                    valor:"Batería interna de iones de litio, Fuente de alimentación empotrada"
                },
                {
                    titulo_descriptor_producto:"Dimensiones ( AxAxP)",
                    valor:"252 x 262 x 278 mm"
                },
                {
                    titulo_descriptor_producto:"Peso neto",
                    valor:"Variante de termómetro de oído 3,7 kg "
                }
            ]
        }
    }

    componentDidMount(){
        this._isMounted = true;
        this.obtenerFichaTecnica();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    obtenerFichaTecnica(){
        console.log("el producto seleccionado antes de obtener ficha: ", this.props.productoSeleccionado.id_producto);

        //si el producto es demo, no haga la consulta
        if(!(!!this.props.productoSeleccionado.id_producto)) return false;

        fetch(`${API.getFichaTecnica}?producto_id="${this.props.productoSeleccionado.id_producto}"`)
        .then(data=>data.json())
        .then(jsonData=>{
            console.log(jsonData);
            if(this._isMounted){
                console.log("abajo la ficha tecnica: ------------------");
                console.log(jsonData);
                this.setState({
                    fichaTecnica: jsonData
                });
                console.log("en el state es este: ", this.state.menuProductos);
            }  
        })
        .catch(err=>console.log("el error: ",err))
    }

    render() {
        console.log(this.props.routerProps);
        return (
            <div className="contenedor_contenido_app">
                <BannerPrincipal imgBannerPrincipal={this.props.productoSeleccionado.img_banner} />
                <DescripcionStandard descriptionObject={this.props.productoSeleccionado} />
                <FichaTecnicaContainer fichaTecnica={this.state.fichaTecnica} />
                <VolverListadoProductos _routerProps={this.props.routerProps} />
                <Footer />
            </div>
        )
    }
}

export default Producto;