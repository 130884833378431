import React from 'react';

const DescripcionStandard=(props)=>{
    return(
        <section className="descripcion_standard_section text_gray_light">
            <span className="title_description text_regular_27">{props.descriptionObject.nombre}</span>
            <hr className="hr_description" />
            <p className="content_description" style={{display:props.display_value}}>{props.descriptionObject.descripcion}</p>
        </section>
    )
}

export default DescripcionStandard;