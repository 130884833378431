import React from 'react';
import {
  BrowserRouter as Router,
  HashRouter,
  Switch,
  Route
} from "react-router-dom";

import Inicio from './Components/Inicio';
import Producto from './Components/Producto';
import ListadoProducto from './Components/ListadoProducto';
import Servicios from './Components/Servicios';
import Clinicas from './Components/Clinicas';
import Proveedores from './Components/Proveedores';
import Intranet from './Components/Intranet';
import Contacto from './Components/Contacto';
import Header from './Components/Parts/GeneralParts/Header';

class App extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      menuElements:[
        {
          id:"menuInicio",
          name:"Inicio",
          route:"/"
        },
        {
          id:"menuProductos",
          name:"Productos",
          route:"/productos"
        },
        {
          id:"menuServicios",
          name:"Servicios",
          route:"/servicios"
        },
        {
          id:"menuClinicas",
          name:"Clientes",
          route:"/clientes"
        },
        {
          id:"menuProveedores",
          name:"Proveedores",
          route:"/proveedores"
        },
        {
          id:"menuIntranet",
          name:"Intranet",
          route:"/intranet"
        },
        {
          id:"menuContacto",
          name:"Contacto",
          route:"/contacto"
        }
      ],
      activeMenuElement:"menuInicio",
      productoSeleccionado:{
        img:"./img/miniatura_demo_producto_1.jpg",
        img_banner:[{url:"./img/miniatura_demo_producto_1_banner.jpg", alt:"texto_alternativo"}],
        titulo:"Titulo del Producto",
        descripcion:"Breve Fragmento de la descripción del producto..."
      },
      categoriasSeleccionadas:{}
    }
  }

  componentDidMount(){
    this.definirPaginaActivo()
    console.log("abajo las PROPS PARAM RUTA")
    console.log(this.props)
  }

  definirPaginaActivo(){
    switch(window.location.pathname){
      case "/":
        this.cambiarActiveMenuElement("menuInicio");
        break;
      case "/productos":
        this.cambiarActiveMenuElement("menuProductos");
        break;
      case "/detalle_producto":
        this.cambiarActiveMenuElement("menuProductosDetalle");
        break;
      case "/servicios":
        this.cambiarActiveMenuElement("menuServicios");
        break;
      case "/clientes":
        this.cambiarActiveMenuElement("menuClinicas");
        break;
      case "/proveedores":
        this.cambiarActiveMenuElement("menuProveedores");
        break;
      case "/contacto":
        this.cambiarActiveMenuElement("menuContacto");
        break;                              
    }
  }

  definirCategoriasSeleccionadas(categoriasSeleccionadas){
    //alert(JSON.stringify(categoriasSeleccionadas));
    this.setState({
      categoriasSeleccionadas
    })
  }

  cambiarActiveMenuElement(e){
    this.setState({
      activeMenuElement:e
    })
  }

  obtenerProductoSeleccionado(productoSeleccionado){
    this.setState({
      productoSeleccionado
    });
    console.log("se detona obtener producto seleccionado y es: ",productoSeleccionado);
  }

  render(){
    return (
      <Router >

        <Header activeMenuElement={this.state.activeMenuElement}
                cambiarActiveMenuElement={(selected)=>{this.cambiarActiveMenuElement(selected)}} />

        <Switch>

          <Route exact path="/"
          component={
            (props)=><Inicio routerProps={props}
            cambiarActiveMenuElement={(selected)=>{this.cambiarActiveMenuElement(selected)}
            }
            definirCategoriasSeleccionadas= { (selected)=>{this.definirCategoriasSeleccionadas(selected)} }
            categoriasSeleccionadas={this.state.categoriasSeleccionadas}
            >
            </Inicio>
          } />

          <Route exact path="/productos" component={(props)=><ListadoProducto routerProps={props}
            cambiarActiveMenuElement={(selected)=>{this.cambiarActiveMenuElement(selected)}}
            obtenerProductoSeleccionado= { (selected)=>{this.obtenerProductoSeleccionado(selected)} }
            definirCategoriasSeleccionadas= { (selected)=>{this.definirCategoriasSeleccionadas(selected)} } 
            categoriasSeleccionadas={this.state.categoriasSeleccionadas}     
            >
            </ListadoProducto>} />

          <Route exact path="/detalle_producto" component={(props)=><Producto routerProps={props}
            cambiarActiveMenuElement={(selected)=>{this.cambiarActiveMenuElement(selected)}}
            productoSeleccionado={this.state.productoSeleccionado}
            >
            </Producto>} />

          <Route exact path="/servicios" component={(props)=><Servicios routerProps={props}
            cambiarActiveMenuElement={(selected)=>{this.cambiarActiveMenuElement(selected)}
            }>
            </Servicios>} />
            
          <Route exact path="/clientes" component={(props)=><Clinicas routerProps={props}
            cambiarActiveMenuElement={(selected)=>{this.cambiarActiveMenuElement(selected)}
            }>
            </Clinicas>} />

          <Route exact path="/proveedores" component={(props)=><Proveedores routerProps={props}
            cambiarActiveMenuElement={(selected)=>{this.cambiarActiveMenuElement(selected)}
            }>
            </Proveedores>} />

          <Route exact path="/contacto" component={(props)=><Contacto routerProps={props}
            cambiarActiveMenuElement={(selected)=>{this.cambiarActiveMenuElement(selected)}
            }>
            </Contacto>} />

          <Route exact path="*"component={(props)=><Inicio routerProps={props}
            cambiarActiveMenuElement={(selected)=>{this.cambiarActiveMenuElement(selected)}
            }>
            </Inicio>}
            menuElements={this.state.menuElements} />

        </Switch>

      </Router>
    );
  }
}

export default App;

