import React from 'react';

const SolicitarEmpleo = ()=>{
    return(
        <section className="seccion_trabaja_con_nosotros">
            <div className="container_address">
                <ul>
                    <li className="text_light_21">
                        Cali
                        <span className="separator_ul_address"></span>
                    </li>
                    <li>Calle. 9 B No. 42 30. Los Cámbulos <br/>
                        info@madhos.co
                    </li>
                </ul>
            </div>
            <div className="container_job">
                <ul>
                    <li>
                        <img src="./img/icon_job.png" className="img_solicitud_empleo" alt="trabaja_con_nosotros"/>
                    </li>
                    <li className="text-white text_bold_1 text_job" >
                        SOLICITUD <br className="br_solicitud_empleo" />
                        DE EMPLEO
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default SolicitarEmpleo;