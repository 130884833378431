import React from 'react';
//import history from "../../../Utils/history";

const ItemGaleriaProducto=(props)=>{

    const goToProduct=()=>{
        props.obtenerProductoSeleccionado(props._product);
        props.routerProps.history.push("/detalle_producto");
    }

    const cortarTexto=(texto, numeroCaracteres)=>{
        var descripcionCorta = (texto.length > (numeroCaracteres + 3)) ? `${texto.substr(0,numeroCaracteres)}...` : texto;
        return descripcionCorta;
    }

    return(
        <ul className="ul_item_galeria_producto" onClick={()=>{goToProduct()}} >
            <li>
                <img className="img_item_galeria_producto" src={props._product.img_miniatura} alt="miniatura_producto"/>
            </li>
            <li>
                <span className="text_blue_dark text_light_15 text_bold_1 titulo_item_galeria_producto">{
                /*Producto de Ejemplo*/
                cortarTexto(props._product.nombre,27)
                }</span>
            </li>
            <li>
                <p className="text_gray_light text_light_14 line_height_19" style={{maxWidth: '75ch'}}>{
                //"Breve Fragmento de la descripción del producto..."
                cortarTexto(props._product.descripcion, 46)
                }</p>
            </li>
        </ul>
    )
}

export default ItemGaleriaProducto;