import React from 'react';

const FichaTecnicaPropiedades=(props)=>{

    const generarSaltoLinea = (texto)=>{
        return texto.replace(/\r\n/g, "<br />");
    }

    function jsxSaltosLinea(texto){
        var arrayTexto = texto.split("\r\n");
        console.log("el array dl texto :");
        console.log(arrayTexto);
        return arrayTexto.map(item=>{
          return (
            <div>
            {item}
            </div>
          )
        })
      }
    
    return(
        <React.Fragment>
            <li className="text_bold_2">{props.propertyObject.titulo_descriptor_producto}</li>
            <li>
                { jsxSaltosLinea(props.propertyObject.valor) }
            </li>
        </React.Fragment>
    )
}

export default FichaTecnicaPropiedades;