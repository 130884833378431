import React from "react";

const Footer = () => {
  return(
    <footer className="footer_rights_reserved">
        <p className="footer_text">
            Política de Privacidad | Política de Uso | Reglamento MADHOS <br />
            © 2019 MADHOS Todos los derechos reservados.<br />
        </p>
    </footer>
  );  
}

export default Footer;



