import React from 'react';
import ItemGaleriaProducto from './ItemGaleriaProducto';

const GaleriaProductos=(props)=>{

    const insertarProducto = ()=>{
        var _routerProps = props.routerProps;
        return props.productosVisibles.map((_product, i) => {
            return(
                <ItemGaleriaProducto routerProps={_routerProps} key={i} _product={_product} obtenerProductoSeleccionado={props.obtenerProductoSeleccionado} />             
            )
          })
    }

    return(
        <div className="galeria_productos_container">
            {insertarProducto()}
        </div>
    )
}

export default GaleriaProductos;