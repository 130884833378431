import React from 'react';
import FichaTecnicaPropiedades from './FichaTecnicaPropiedades';

const FichaTecnicaContainer=(props)=>{
    
    const listarPropiedades = ()=>{
        return props.fichaTecnica.map((_prop, index) => {
            return(
                <FichaTecnicaPropiedades propertyObject={_prop} key={`prop_container_${index}`} />
            )
          })
    }

    return(
        <section className="ficha_tecnica_section text_gray_light">
            <span className="text-dark text_regular_23">Ficha Técnica del Producto</span>
            <span className="separator_ul_address"></span>
            <ul className="ficha_tecnica_ul">
                {listarPropiedades()}
            </ul>           
        </section>
    )
}

export default FichaTecnicaContainer;