import React from 'react';
import Slider from "react-slick";

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
      className={className}
        style={{ ...style, display: "block", background: "transparent", color:"transparent" }}
        onClick={onClick}>

      </div>
    );
  }

class BannerPrincipal extends React.Component {

    constructor(props){
        super(props);
        this.state = {
          mostrarArrows : !(window.innerWidth <= 1024)
        };
    }

  render() {

    const settings = {
      className: "center main_banner_slick",
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 4000,
      cssEase: "ease-in-out",
      nextArrow: <SamplePrevArrow />,
      prevArrow: <SamplePrevArrow />,
      arrows: this.state.mostrarArrows
    };

    const insertarImagenes = ()=>{
      console.log("propiedades en insetarImagenes ");
      console.log(this.props);
        return this.props.imgBannerPrincipal.map((_img, i) => {
            let classes = (i===0) ? "carousel-item active" : "carousel-item" 
            return(
                <div className={classes} key={i}>
                    <img className="d-block img_100" style={{width :"100%"}} src={_img.url} alt={_img.alt} />
                </div>                
            )
          })
    }

    return (
      <div className="slide_index">
        <Slider {...settings}>
            {insertarImagenes()}
        </Slider>
      </div>
    );
  }
}

export default BannerPrincipal;