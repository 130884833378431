import React from 'react';
import Slider from "react-slick";

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
      className={className}
        style={{ ...style, display: "block", background: "transparent", color:"transparent" }}
        onClick={onClick}>

      </div>
    );
  }

class CategoriasProductos extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      numeroDeFilas:(window.innerWidth <= 720) ? 1 : 2,
      numeroDeSlides:(window.innerWidth <= 720) ? 2 : 4
    }
  }

  evaluarCategoria(name){
    var categoriaSeleccionada = {};
    switch (name) {
      case "Ropa Desechable":
        categoriaSeleccionada = {65:{}}
        break;
      case "Insumos Hospitalarios": 
      categoriaSeleccionada = {1:{}}
        break;
      case "Terapia Respiratoria": 
      categoriaSeleccionada = {18:{}}
        break;
      case "Instrumental Quirúrgico":
        categoriaSeleccionada = {37:{}}
        break;
      case "Material Osteosíntesis":
        categoriaSeleccionada = {48:{}}
        break;
      case "Terapia Intravenosa":
        categoriaSeleccionada = {9:{}}
        break;
      case "Insumos Esterilización":
        categoriaSeleccionada = {30:{}}
        break;
      case "Insumos Alto Costo":
        categoriaSeleccionada = {40:{}}
        break;
      case "Terapia Cuidado Crítico":
        categoriaSeleccionada = {45:{}}
        break;
      case "Cuidado de Heridas":
        categoriaSeleccionada = {57:{}}
        break;
      case "Heridas y Cicatrización":
        categoriaSeleccionada = {61:{}}
        break;   
      case "División Diabetis":
        categoriaSeleccionada = {73:{}}
        break;    
      case "Insumos Quirúrgicos":
        return {77:{}}
        break;   
      case "Línea de Alimentación":
        categoriaSeleccionada = {89:{}}
        break;   
      case "Acceso Vascular":
        categoriaSeleccionada = {94:{}}
        break;   
      case "Cuidado Ostonomía":
        categoriaSeleccionada = {94:{}}
        break;   
      case "Alimentos Nutricionales":
        categoriaSeleccionada = {97:{}}
        break;   
      case "Equipos Biomédicos":
        categoriaSeleccionada = {105:{}}
        break;                                                                                                                              
      default:
        console.log('default case');
    }
    return categoriaSeleccionada;
  }

  render() {
/*
    const settings = {
      className: "center categorias_productos",
      centerMode: false,
      infinite: false,
      adaptiveHeight:true,
      centerPadding: "60px",
      slidesToShow: 4,
      speed: 500,
      rows: 2,
      slidesPerRow: 1,
      nextArrow: <SamplePrevArrow />,
      prevArrow: <SamplePrevArrow />
    };*/

    const settings = {
      className: "center categorias_productos",
      centerMode: false,
      infinite: false,
      adaptiveHeight:true,
      centerPadding: "60px",
      slidesToShow: this.state.numeroDeSlides, //2 en celulares, 4 en desktop
      speed: 500,
      rows: this.state.numeroDeFilas, //1 fila en celulares, 2 en desktop
      slidesPerRow: 1,
      nextArrow: <SamplePrevArrow />,
      prevArrow: <SamplePrevArrow />
    };

    const irProductos=(categoria)=>{
      console.log("category es: ",categoria);
        this.props.cambiarActiveMenuElement("menuProductos");
        this.props.routerProps.history.push("/productos");
        const _evaluarCategoria = this.evaluarCategoria(categoria.name);

        //if(categoria.name === "Material Osteosíntesis"){
           this.props.definirCategoriasSeleccionadas(
            _evaluarCategoria
             );
        //}  

    }



    const insertarCategorias=()=>{
        return this.props.categoriasPadreProducto.map(_category=>{
            return(
                <div className="category_ul_container" key={_category.id} onClick={()=>{irProductos(_category)}}>
                    <ul>
                        <li><img src={_category.img} className="img_icon_categoria_producto" alt="" /></li>
                        <li>{_category.name}</li>
                    </ul>
                </div>
            )
        })
    }

    return (
      <div className="contenedor_categorias_productos_index">
        <Slider {...settings}>
            {insertarCategorias()}
        </Slider>
      </div>
    );
  }
}

export default CategoriasProductos;