const menuProductos = [
    {
        name:"Ropa Desechable",
        img:"./img/icon_ropa_desechable.png",
        subOptions:[
            {
                name:"Bata Cirujano",
                subOptions:[],
                id:"60"
            },
            {
                name:"Paquete Laparotomía",
                subOptions:[],
                id:"61"
            },
            {
                name:"Paquete Angiogratía",
                subOptions:[],
                id:"62"
            },
            {
                name:"Bata Paciente",
                subOptions:[],
                id:"63"
            },
            {
                name:"Campos Envolvedero",
                subOptions:[],
                id:"64"
            },
            {
                name:"Tapabocas",
                subOptions:[],
                id:"_65"
            },
            {
                name:"Polainas",
                subOptions:[],
                id:"66"
            }
        ],
        id:"65"
    },
    {
        name:"Material Osteosíntesis",
        img:"./img/icon_material_osteosintesis.png",
        subOptions:[
            {
                name:"Trauma",
                subOptions:[],
                id:"49"
            },
            {
                name:"Reemplazos Articulares",
                subOptions:[],
                id:"44"
            },
            {
                name:"Columna",
                subOptions:[],
                id:"45"
            },
            {
                name:"Fijación Extrema",
                subOptions:[],
                id:"46"
            },
            {
                name:"Osteobiológicos",
                subOptions:[],
                id:"47"
            },
            {
                name:"Maxilofacial",
                subOptions:[],
                id:"48"
            },
            {
                name:"Minifragmentos",
                subOptions:[],
                id:"43"
            },
            {
                name:"Motores",
                subOptions:[],
                id:"50"
            } 

        ],
        id:"51"
    },
    {
        name:"Insumos Hospitalarios",
        img:"./img/icon_insumos_hospitalarios.png",
        subOptions:[
            {
                name:"Guantes Examinación",
                subOptions:[],
                id:"1"
            },
            {
                name:"Jeringas",
                subOptions:[],
                id:"2"
            },
            {
                name:"Guantes Esterilización",
                subOptions:[],
                id:"3"
            }                        
        ],
        id:"4"
    },
    {
        name:"Terapia Intravenosa",
        img:"./img/icon_terapia_intravenosa.png",
        subOptions:[
            {
                name:"Buretrol",
                subOptions:[],
                id:"5"
            },
            {
                name:"Macrogoteo",
                subOptions:[],
                id:"6"
            },
            {
                name:"Microgoteo",
                subOptions:[],
                id:"7"
            },
            {
                name:"Equipo Pericraneal",
                subOptions:[],
                id:"8"
            },
            {
                name:"Equipo Adm. Sangre",
                subOptions:[],
                id:"9"
            },
            {
                name:"Tapón Heparimizado",
                subOptions:[],
                id:"10"
            },
            {
                name:"Cateter Intravenosos",
                subOptions:[],
                id:"11"
            }                        
        ],
        id:"12"
    },
    {
        name:"Terapia Respiratoria",
        img:"./img/icon_terapia_respiratoria.png",
        subOptions:[
            {
                name:"Canulas Nasales",
                subOptions:[],
                id:"13"
            },
            {
                name:"Máscaras Oxigeno",
                subOptions:[],
                id:"14"
            },
            {
                name:"Kit Ventura",
                subOptions:[],
                id:"15"
            },
            {
                name:"Humidificadores",
                subOptions:[],
                id:"16"
            },
            {
                name:"Filtros",
                subOptions:[],
                id:"17"
            },
            {
                name:"Circuitos Ventilación",
                subOptions:[],
                id:"18"
            },
            {
                name:"Máscaras Anestesia",
                subOptions:[],
                id:"19"
            },
            {
                name:"Balones Anestesia",
                subOptions:[],
                id:"20"
            },
            {
                name:"Cateter Mount",
                subOptions:[],
                id:"21"
            },
            {
                name:"Circuito Anestesia",
                subOptions:[],
                id:"22"
            },
            {
                name:"Trampas Luke",
                subOptions:[],
                id:"23"
            }                        
        ],
        id:"24"
    },
    {
        name:"Insumos Esterilización",
        img:"./img/icon_insumos_para_esterilizacion.png",
        subOptions:[
            {
                name:"Papel Crepado",
                subOptions:[],
                id:"25"
            },
            {
                name:"Papel Grado Médico",
                subOptions:[],
                id:"26"
            },
            {
                name:"Indicadores Biológicos",
                subOptions:[],
                id:"27"
            },
            {
                name:"Cintas Indicadoras",
                subOptions:[],
                id:"28"
            },
            {
                name:"Indicadores Químicos",
                subOptions:[],
                id:"29"
            },
            {
                name:"test Bowie Dick",
                subOptions:[],
                id:"30"
            }                   
        ],
        id:"31"
    },
    {
        name:"Instrumental Quirúrgico",
        img:"./img/icon_instrumental_quirurgico.png",
        subOptions:[
            {
                name:"Básico",
                subOptions:[],
                id:"32"
            },
            {
                name:"Especializado",
                subOptions:[],
                id:"33"
            }                 
        ],
        id:"34"
    },
    {
        name:"Insumos Alto Costo",
        img:"./img/icon_equipos_biomedicos.png",
        subOptions:[
            {
                name:"Terapia Vac.",
                subOptions:[],
                id:"35"
            },
            {
                name:"Kit de Traqueostomía",
                subOptions:[],
                id:"36"
            },
            {
                name:"Cateter Doble",
                subOptions:[],
                id:"37"
            },
            {
                name:"Bombas Irrigación Artus",
                subOptions:[],
                id:"38"
            }                  
        ],
        id:"39"
    },
    {
        name:"Terapia Cuidado Crítico",
        img:"./img/icon_terapia_cuidado_crìtico.png",
        subOptions:[
            {
                name:"Transductores de Presión",
                subOptions:[],
                id:"40"
            },
            {
                name:"Infusores de Presión",
                subOptions:[],
                id:"41"
            }                
        ],
        id:"42"
    },
    {
        name:"Cuidado de Heridas",
        img:"./img/icon_cuidado_heridas.png",
        subOptions:[
            {
                name:"Apósitos Especiales",
                subOptions:[],
                id:"52"
            },
            {
                name:"Compresas",
                subOptions:[],
                id:"53"
            },
            {
                name:"Apósitos Transparentes",
                subOptions:[],
                id:"54"
            }
        ],
        id:"55"
    },
    {
        name:"Heridas y Cicatrización",
        img:"./img/icon_cierre_heridas.png",
        subOptions:[
            {
                name:"Cintas Adhesivas",
                subOptions:[],
                id:"56"
            },
            {
                name:"Vendajes",
                subOptions:[],
                id:"57"
            },
            {
                name:"Yesos",
                subOptions:[],
                id:"58"
            }
        ],
        id:"59"
    },
    {
        name:"División Diabetis",
        img:"./img/icon_division_diabetis.png",
        subOptions:[
            {
                name:"Glocómetro",
                subOptions:[],
                id:"68"
            },
            {
                name:"Lancetas",
                subOptions:[],
                id:"69"
            },
            {
                name:"Tiras",
                subOptions:[],
                id:"70"
            }
        ],
        id:"71"
    },
    {
        name:"Insumos Quirúrgicos",
        img:"./img/icon_instrumental_quirurgico.png",
        subOptions:[
            {
                name:"Insumos Laparoscopia",
                subOptions:[],
                id:"72"
            },
            {
                name:"Sistema Recolección Fluidos",
                subOptions:[],
                id:"73"
            },
            {
                name:"Contenedores",
                subOptions:[],
                id:"74"
            },
            {
                name:"Suturas Mecánicas",
                subOptions:[],
                id:"75"
            },
            {
                name:"Agujas para Anestesia",
                subOptions:[],
                id:"76"
            },
            {
                name:"Hemostáticos",
                subOptions:[],
                id:"77"
            },
            {
                name:"Clips de Cordón Umbilical",
                subOptions:[],
                id:"78"
            },
            {
                name:"Tubos Endotraqueales",
                subOptions:[],
                id:"79"
            },
            {
                name:"Mallas Quirúrgicas",
                subOptions:[],
                id:"80"
            },
            {
                name:"Placas Electros",
                subOptions:[],
                id:"81"
            },
            {
                name:"Lápiz Bisturí",
                subOptions:[],
                id:"82"
            }
        ],
        id:"83"
    },
    {
        name:"Línea de Alimentación",
        img:"./img/icon_linea_alimentacion.png",
        subOptions:[
            {
                name:"Kit de Gastronomía",
                subOptions:[],
                id:"84"
            },
            {
                name:"Bolsas de Alimentación",
                subOptions:[],
                id:"85"
            }
        ],
        id:"86"
    },
    {
        name:"Acceso Vascular",
        img:"./img/icon_acceso_vascular.png",
        subOptions:[
            {
                name:"Cateter Central",
                subOptions:[],
                id:"87"
            }
        ],
        id:"88"
    },
    {
        name:"Cuidado Ostomía",
        img:"./img/icon_equipos_biomedicos.png",
        subOptions:[
            {
                name:"Bolsa Colostomía",
                subOptions:[],
                id:"89"
            },
            {
                name:"Accesorios",
                subOptions:[],
                id:"90"
            }
        ],
        id:"91"
    },
    {
        name:"Alimentos Nutricionales",
        img:"./img/icon_alimentos_nutricionales.png",
        subOptions:[
            {
                name:"Dumisoy D",
                subOptions:[],
                id:"92"
            },
            {
                name:"Dumisue",
                subOptions:[],
                id:"93"
            },
            {
                name:"D soy",
                subOptions:[],
                id:"94"
            },
            {
                name:"Actisure Adulto",
                subOptions:[],
                id:"95"
            },
            {
                name:"Actisure Niño",
                subOptions:[],
                id:"96"
            }
        ],
        id:"97"
    },
    {
        name:"Acceso Vascular",
        img:"./img/icon_acceso_vascular.png",
        subOptions:[
            {
                name:"Cateter Venoso Central",
                subOptions:[],
                id:"98"
            }
        ],
        id:"99"
    },
    {
        name:"Equipos Biomédicos",
        img:"./img/icon_equipos_biomedicos.png",
        subOptions:[
            {
                name:"Monitoreo",
                subOptions:[
                    {
                        name:"Electrocardiógrafo",
                        subOptions:[],
                        id:"101"
                    },
                    {
                        name:"Holter",
                        subOptions:[],
                        id:"102"
                    },
                    {
                        name:"Monitor de Signos Vitales",
                        subOptions:[],
                        id:"103"
                    },
                    {
                        name:"Monitor Fetal",
                        subOptions:[],
                        id:"104"
                    },
                    {
                        name:"Pulsioxímetro Portátil",
                        subOptions:[],
                        id:"105"
                    }
                ],
                id:"106"
            },
            {
                name:"Cuidado Instensivo",
                subOptions:[
                    {
                        name:"Ventilador Mecánico",
                        subOptions:[],
                        id:"107"
                    },
                    {
                        name:"Incubadora Neonatal",
                        subOptions:[],
                        id:"108"
                    },
                    {
                        name:"Lámpara de Calor Radiante",
                        subOptions:[],
                        id:"109"
                    }
                ],
                id:"110"
            },
            {
                name:"Diagnóstico",
                subOptions:[
                    {
                        name:"Unidad de Rayos x Fijo",
                        subOptions:[],
                        id:"111"
                    },
                    {
                        name:"Unidad de Rayos x Portatil",
                        subOptions:[],
                        id:"112"
                    },
                    {
                        name:"Intensificador de Imágenes",
                        subOptions:[],
                        id:"113"
                    }
                ],
                id:"114"
            },
            {
                name:"Patología",
                subOptions:[
                    {
                        name:"Microtomo",
                        subOptions:[],
                        id:"115"
                    },
                    {
                        name:"Procesador de Tegidos",
                        subOptions:[],
                        id:"116"
                    },
                    {
                        name:"Baño de Flotación",
                        subOptions:[],
                        id:"117"
                    },
                    {
                        name:"Dispensador de Parafina",
                        subOptions:[],
                        id:"118"
                    }
                ],
                id:"119"
            },
            {
                name:"Esterilización",
                subOptions:[
                    {
                        name:"Incubadora y Lectura de Indicadores",
                        subOptions:[],
                        id:"120"
                    },
                    {
                        name:"Esterilizador de óxido de Etileno",
                        subOptions:[],
                        id:"121"
                    },
                    {
                        name:"Autoclave de Vapor",
                        subOptions:[],
                        id:"122"
                    }
                ],
                id:"123"
            },
            {
                name:"Cirugía",
                subOptions:[
                    {
                        name:"Dermatomo",
                        subOptions:[],
                        id:"124"
                    }
                ],
                id:"125"
            },
            {
                name:"Dotación Hospitalaria",
                subOptions:[
                    {
                        name:"Camas Mecánicas",
                        subOptions:[],
                        id:"126"
                    },
                    {
                        name:"Camillas de Transporte",
                        subOptions:[],
                        id:"127"
                    },
                    {
                        name:"Cunas Acrílicas",
                        subOptions:[],
                        id:"128"
                    },
                    {
                        name:"Carro de Paro",
                        subOptions:[],
                        id:"129"
                    },
                    {
                        name:"Carro de Medicamentos",
                        subOptions:[],
                        id:"130"
                    },
                    {
                        name:"Mesas Puente",
                        subOptions:[],
                        id:"131"
                    },
                    {
                        name:"Mesas de Noche",
                        subOptions:[],
                        id:"132"
                    }
                ],
                id:"133"
            },
            {
                name:"Gases Medicinales",
                subOptions:[
                    {
                        name:"Compresor de Aire",
                        subOptions:[],
                        id:"134"
                    },
                    {
                        name:"Tomas de Gases",
                        subOptions:[],
                        id:"135"
                    },
                    {
                        name:"Adaptadores Tipo Chemetron",
                        subOptions:[],
                        id:"136"
                    }
                ],
                id:"137"
            }
        ],
        id:"107"
    }
]

export default menuProductos;