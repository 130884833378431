import React from 'react';
import Footer from './Parts/GeneralParts/Footer';
import SolicitarEmpleo from './Parts/GeneralParts/SolicitarEmpleo';
import SlideClientes from './Parts/GeneralParts/SlideClientes';
import InformacionEmpresa from './Parts/InformacionEmpresa';
import CategoriasProductos from './Parts/Inicio/CategoriasProductos';
import BannerPrincipal from './Parts/GeneralParts/BannerPrincipal';
import menuProductos from './Parts/infoDemoMenu';
import listadoClientes from './Parts/infoDemoClientes';

class Inicio extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            imgBannerPrincipal:[
                {
                    alt:"first_slide",
                    url:"./img/slide_1.jpg"
                },
                {
                    alt:"second_slide",
                    url:"./img/slide_3.jpg"
                }
            ],
            categoriasPadreProducto:menuProductos,
            imagenesClientes:listadoClientes
        }
    }
    render() {

        var bannerPrincipal = ()=>{
            return(
                <BannerPrincipal imgBannerPrincipal={this.state.imgBannerPrincipal} />
            );
        }
        return (
            <div className="contenedor_contenido_app">
                {bannerPrincipal()}
                <CategoriasProductos
                    categoriasPadreProducto={this.state.categoriasPadreProducto}
                    cambiarActiveMenuElement={this.props.cambiarActiveMenuElement}
                    definirCategoriasSeleccionadas={(selected)=>{this.props.definirCategoriasSeleccionadas(selected)}}
                    categoriasSeleccionadas={this.props.categoriasSeleccionadas}
                    routerProps={this.props.routerProps} />
                <InformacionEmpresa />
                <SlideClientes imagenesClientes={this.state.imagenesClientes} />
                <SolicitarEmpleo />
                <Footer />
            </div>
        )
    }
}

export default Inicio;