import React from 'react';

class Intranet extends React.Component {
    //window.locationf="http://www.cristalab.com";
    componentWillMount(){
        console.log("hhola amigooo");
        window.location="http://www.cristalab.com";
    }

    render(){
        return <div></div>
    }
};

export default Intranet;
