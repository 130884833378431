import React from 'react';

const VolverListadoProductos=(props)=>{

    const goToProduct=()=>{
        props._routerProps.history.push("/productos");
    }

    return(
        <section className="seccion_volver_listado_producto">
            <div onClick={()=>{goToProduct()}}>
                <img src="./img/icon_back_btn.png" alt="back"/>
                <span className="text_bold_2 text_blue_light atras_btn">ATRÁS</span>
            </div>
        </section>
    )
}

export default VolverListadoProductos;